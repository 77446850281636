
.App-header {
  background-color: rgb(25, 24, 24);
  min-height: 15vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  position: sticky;
  padding-left: 25px;
  padding-right: 25px;
  z-index: 1;
}

.header {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: calc(40px + 2vmin);
  letter-spacing: 5px;
  text-decoration: none;
  color: darkgray;
}

.header:hover {
  text-shadow:
  0 0 7px #fff,
  0 0 10px #fff,
  0 0 21px #fff,
  0 0 42px rgb(120, 2, 141),
  0 0 82px rgb(120, 2, 141),
  0 0 92px rgb(120, 2, 141),
  0 0 102px rgb(120, 2, 141),
  0 0 151px rgb(120, 2, 141);
}

.header-icon:hover{
  cursor: pointer;
}

.header-tag{
  text-decoration: none;
  color: white;
  font-size: 20px;
  letter-spacing: 5px;
}

.header-home-tag{
  text-decoration: none;
  color: grey;
  font-size: 30px;
  letter-spacing: 5px;
}

.header-home-tag:hover {
  text-shadow:
  0 0 7px #fff,
  0 0 10px #fff,
  0 0 21px #fff,
  0 0 42px rgb(120, 2, 141),
  0 0 82px rgb(120, 2, 141),
  0 0 92px rgb(120, 2, 141),
  0 0 102px rgb(120, 2, 141),
  0 0 151px rgb(120, 2, 141);
}

.header-tag:hover, .header-home-tag:hover{
  text-decoration: underline;
}

.header-list{
  text-align: center;
}