.bio-main{
  background-color: #282c34;
  min-height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;  
}


.bio-p, .bio-stack-sm{
  padding-top: 50px;
  padding-bottom: 50px;
}

.bio-p1, .bio-p2, .bio-p3, .bio-p4{
  padding-left: 25px;
  padding-right: 25px;
  
}

.bio-p4 a{
  text-decoration: none;
  color: white;
}

.bio-p4 a:hover{
  cursor: pointer;
  text-shadow:
  0 0 7px #fff,
  0 0 10px #fff,
  0 0 21px #fff,
  0 0 42px rgb(120, 2, 141),
  0 0 82px rgb(120, 2, 141),
  0 0 92px rgb(120, 2, 141),
  0 0 102px rgb(120, 2, 141),
  0 0 151px rgb(120, 2, 141);
}

.bio-img{
  object-fit: cover;
  float: right;
  min-height: 85vh;
  width: 40%;
}

.bio-img-sm{
  background-image: url("../../files/ame.png");
  background-position: top;
  background-size:cover;
  height: 400px;
  width: 100%;
}

/* EXPERIENCE AND SKILLS PAGE */
/*///////////////////////////*/
.ens-main{
  background-color: #282c34;
  min-height: 85vh;
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ens-container{
  width: 100%;
  padding: 25px;
}

.ens-stack{
  margin-top: 45px;
  margin-bottom: 45px;
  
}

.ens-text-container{
  width: 100%;
}

.ens-headers{
  color: black;
  /* border: 3px solid rgb(0, 0, 0); */
  text-decoration: underline;
  text-shadow:
  0 0 2px #fff,
  0 0 5px #fff,
  0 0 5px #fff,
  0 0 6px rgb(162, 106, 173),
  0 0 8px rgb(162, 106, 173),
  0 0 11px rgb(162, 106, 173),
  0 0 15px rgb(162, 106, 173),
  0 0 18px rgb(162, 106, 173);

}

.ens-title{
  font-weight: bold;
  padding-right: 10px;
  color: white;
  text-decoration: underline;

}

.ens-date{
  font-style: italic;
  padding-left: 10px;
  
}

.ens-btn{
  width: 80%;
  background-color: rgb(146, 107, 159, .5);
  border-radius: 3px;;
  border: 3px solid rgb(146, 107, 159);
  color: rgb(95, 55, 95);
  text-shadow:
  0 0 2px #fff,
  0 0 5px #fff,
  0 0 5px #fff,
  0 0 6px rgb(162, 106, 173),
  0 0 8px rgb(162, 106, 173),
  0 0 11px rgb(162, 106, 173),
  0 0 15px rgb(162, 106, 173),
  0 0 18px rgb(162, 106, 173);
}

.ens-btn:hover{
cursor: pointer;
box-shadow:
0 0 2px #fff,
0 0 5px #fff,
0 0 5px #fff,
0 0 6px rgb(162, 106, 173),
0 0 8px rgb(162, 106, 173),
0 0 11px rgb(162, 106, 173),
0 0 15px rgb(162, 106, 173),
0 0 18px rgb(162, 106, 173);
}



/* Projects page */
/*//////////////*/
.projects-main{
background-color: #282c34;
min-height: 85vh;
display: flex;
text-align: center;
justify-content: center;
font-size: calc(10px + 2vmin);
color: white;
padding-bottom: 25px;
}

.projects-container{
  width: 100%;
  padding: 25px;
}

.projects-link{
  width: 100%;
  color: white;
}
.projects-link:hover{
  cursor: pointer;
  text-shadow:
  0 0 2px #fff,
  0 0 5px #fff,
  0 0 5px #fff,
  0 0 6px rgb(162, 106, 173),
  0 0 8px rgb(162, 106, 173),
  0 0 11px rgb(162, 106, 173),
  0 0 15px rgb(162, 106, 173),
  0 0 18px rgb(162, 106, 173);
}

.card-text{
  padding: 15px;
}

.card-btn, .card-btn-extra{
  width: 100%;
  /* text-decoration: none; */
  color:#3a3b3d;
}
.card-btn:hover{
  cursor: pointer;
  text-decoration: underline;
  text-decoration-thickness: 1.5px;
}

.card-live-btn {
  width: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.card-live-border {
  font-size: large;
  padding:5px;
  width: 100%;
  background-color: rgba(184, 135, 200, 0.5);
  border-radius: 3px;;
  border: 3px solid rgb(184, 146, 197);
  color: rgb(95, 55, 95);
}

.card-live-border:hover{
  cursor: pointer;
  text-shadow:
  0 0 2px #fff,
  0 0 5px #fff,
  0 0 5px #fff,
  0 0 6px rgb(162, 106, 173),
  0 0 8px rgb(162, 106, 173),
  0 0 11px rgb(162, 106, 173),
  0 0 15px rgb(162, 106, 173),
  0 0 18px rgb(162, 106, 173);
}

.card-live-btn:hover{
  box-shadow:
  0 0 2px #fff,
  0 0 5px #fff,
  0 0 5px #fff,
  0 0 6px rgb(162, 106, 173),
  0 0 8px rgb(162, 106, 173),
  0 0 11px rgb(162, 106, 173),
  0 0 15px rgb(162, 106, 173),
  0 0 18px rgb(162, 106, 173);
}


.card-live-btn .fake-border {
  background: rgb(219, 182, 227);
  border-radius: 5px;
  position: absolute;
  transition: .15s all ease;
}

.card-live-btn .left, .card-live-btn .right {
  width: 3px;
  height: 100%;
}

.card-live-btn .top, .card-live-btn .bottom {
  height: 3px;
  width: 100%;
}

.card-live-btn .left {
  top: 100%;
  transition-delay: 0ms;
}

.card-live-btn .right {
  top: -100%;
  right: 0%;
  transition-delay: 300ms;
}

.card-live-btn:hover .left, .card-live-btn:hover .right {
  top: 0%;
}

.card-live-btn .top {
  left: -100%;
  top: 0;
  transition-delay: 150ms;
}

.card-live-btn .bottom {
  bottom: 0%;
  left: 100%;
  transition-delay: 450ms;
}

.card-live-btn:hover .top, .card-live-btn:hover .bottom {
  left: 0%;
}


/* Contact Page */
/*//////////////*/
.contact-box{
  background-color: #c1bec1;
  display: flex;
  padding: 25px;
  text-align: center;
  margin: 15px;
  border-radius: 5px;
}

.contact-btn{
  width: 100%;
  border-radius: 5px;
}
