.App-footer {
  background-color: rgb(25, 24, 24);
  min-height: 15vh;
  display: flex;
  align-items: center;
  text-align: center;
  
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
  padding-left: 20px;
}

.footer-links{ 
  text-decoration: none;
  font-size: 20px;
  color: white;
  letter-spacing: 8px;
}

.footer-links:hover{
  text-decoration: underline;
}

.footer-grid{
  padding-left: 20px;
  padding-right: 20px;
}
.footer-link-grid{
  padding-left: 20px;
  padding-right: 20px;
}